import React from "react";
import loginLogo from "../../assets/images/logo.png";
import christmasLogo from "../../assets/images/christmasLogoSingle.png";
import ChristmasLight from "../../assets/images/bannerLight.png";
import styles from "./auth_style.module.css";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function AuthPageWrapper({ children }) {
	return (
		<div
			className={`${styles.auth_container} d-flex justify-content-center text-center align-items`}
		>
			<div className={`${styles.auth_card} bg-white`}>
				{checkIfChristmasPeriod() && (
					<div className={styles.christmasLightContainer}>
						<img
							src={ChristmasLight}
							alt="chrsitmas Light"
							className={`${styles.light}`}
						/>
					</div>
				)}
				<div className={`mt-5 pt-2 ${styles.logo}`}>
					<Link to="/">
						<img
							src={
								checkIfChristmasPeriod()
									? christmasLogo
									: loginLogo
							}
							alt="UNN Logo"
							className={`${styles.loginLogo}`}
						/>
					</Link>
				</div>
				{children}
			</div>
		</div>
	);
}
